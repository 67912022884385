import { IconName } from "@fortawesome/fontawesome-svg-core";
import React, { ReactNode } from "react";
import { GetSchoolLayoutLinks } from "@server-types/schools";
import Icon, { IconStyle } from "../Icon";
import { useDropdownMenu } from "./useDropdownMenu";
import styles from "./index.css";

type MenuLinkProps = {
  icon: IconName;
  iconStyle?: IconStyle;
  text: string;
  href: string;
  target?: string;
};

export function MenuLink({
  icon,
  iconStyle,
  text,
  href,
  target = undefined,
}: MenuLinkProps) {
  if (!href) return null;
  return (
    <li>
      <a href={href} target={target}>
        <Icon icon={icon} iconStyle={iconStyle} fixedWidth />
        {text}
      </a>
    </li>
  );
}

export function CohortsMenu({ links }: { links: GetSchoolLayoutLinks }) {
  return (
    <ul className={styles.menu}>
      {links.cohortsAttendance && (
        <MenuLink
          icon="calendar-alt"
          text="Attendance"
          href={links.cohortsAttendance}
        />
      )}
      <MenuLink
        icon="users"
        text="Demographics"
        href={links.cohortsDemographics}
      />
      <MenuLink
        icon="list-alt"
        text="Headlines"
        href={links.cohortsHeadlines}
      />
      <MenuLink icon="columns" text="Ladders" href={links.cohortsLadders} />
      <MenuLink
        icon="file-check"
        text="Learning Plans"
        href={links.cohortsLearningPlans}
      />
      {links.cohortsObjectives && (
        <MenuLink
          icon="check-circle"
          text="Objectives Attainment"
          href={links.cohortsObjectives}
        />
      )}
      <MenuLink
        icon="tasks-alt"
        text="Overviews – Attainment"
        href={links.cohortsAttainmentOverview}
      />
      <MenuLink
        icon="tasks-alt"
        text="Overviews – Progress"
        href={links.cohortsProgressOverview}
      />
      <MenuLink
        icon="house-chimney-user"
        text="Parent Reports"
        href={links.cohortsParentReports}
      />
      <MenuLink
        icon="th-large"
        text="Progress Matrix"
        href={links.cohortsProgressMatrix}
      />
      <MenuLink
        icon="dot-circle"
        iconStyle="far"
        text="Scatter Graph"
        href={links.cohortsScatterGraph}
      />
      <MenuLink
        icon="archive"
        text="Statutory Assessments"
        href={links.cohortsStatutory}
      />
      <MenuLink icon="table" text="Tables" href={links.cohortsTables} />
    </ul>
  );
}

type EnterDataMenuProps = {
  links: {
    enterAssessments: string;
    statutoryMenu: string;
    importExcelAssessments: string;
    thirdPartyData: string;
    enterTags: string;
  };
};

export function EnterDataMenu({ links }: EnterDataMenuProps) {
  return (
    <ul className={styles.menu}>
      <MenuLink
        icon="plus-circle"
        text="School Assessments"
        href={links.enterAssessments}
      />
      <MenuLink
        icon="plus-circle"
        text="Statutory Assessments"
        href={links.statutoryMenu}
      />
      <MenuLink
        icon="upload"
        text="Excel Assessments"
        href={links.importExcelAssessments}
      />
      <MenuLink
        icon="upload"
        text="Third-party Data"
        href={links.thirdPartyData}
      />
      <MenuLink icon="tag" text="Pupil Groups" href={links.enterTags} />
    </ul>
  );
}

type PrimaryLinkProps = {
  icon?: IconName;
  iconStyle?: IconStyle;
  text: string;
  href?: string;
  createDropdown?: () => ReactNode;
};

export function PrimaryLink({
  icon = null,
  iconStyle,
  text,
  href = null,
  createDropdown = null,
}: PrimaryLinkProps) {
  const { handleButtonClick, menu } = useDropdownMenu(createDropdown, "left");

  if (createDropdown) {
    return (
      <>
        <button className={styles.primaryNavLink} onClick={handleButtonClick}>
          {icon && <Icon icon={icon} iconStyle={iconStyle} />}
          {text} <span className={styles.downArrow} />
        </button>
        {menu}
      </>
    );
  }

  if (href) {
    return (
      <a href={href} className={styles.primaryNavLink}>
        {icon && <Icon icon={icon} iconStyle={iconStyle} />}
        {text}
      </a>
    );
  }

  return null;
}

type SecondaryLinkProps = {
  text?: string;
  icon?: IconName;
  iconStyle?: IconStyle;
  href?: string;
  createDropdown?: () => ReactNode;
  muted?: boolean;
  onClick?: () => void;
};

export function SecondaryLink({
  text = null,
  icon = null,
  iconStyle,
  href = null,
  createDropdown = null,
  muted = false,
  onClick = null,
}: SecondaryLinkProps) {
  const { handleButtonClick, menu } = useDropdownMenu(createDropdown, "right");

  if (createDropdown) {
    return (
      <>
        <button className={styles.secondaryNavLink} onClick={handleButtonClick}>
          {icon && <Icon icon={icon} iconStyle={iconStyle} />}
          {text} <span className={styles.downArrow} />
        </button>
        {menu}
      </>
    );
  }

  if (href) {
    return (
      <a href={href} className={styles.secondaryNavLink}>
        {icon ? (
          <Icon
            icon={icon}
            iconStyle={iconStyle}
            style={muted ? { color: "rgba(223, 227, 241, 0.34)" } : null}
          />
        ) : null}
        {text}
      </a>
    );
  }

  if (onClick) {
    return (
      <>
        <button className={styles.secondaryNavLink} onClick={onClick}>
          {icon && <Icon icon={icon} iconStyle={iconStyle} />}
          {text}
        </button>
      </>
    );
  }

  return null;
}

type MyAccountMenuProps = {
  links: {
    organisations?: { url: string; text: string };
    admin?: string;
    userProfile: string;
    changePassword: string;
    privateMode?: string;
    logout: string;
  };
};

export function MyAccountMenu({ links }: MyAccountMenuProps) {
  const { handleButtonClick, menu } = useDropdownMenu(
    () => <DropDown />,
    "right"
  );

  function DropDown() {
    return (
      <ul className={styles.menu}>
        {links.organisations && (
          <MenuLink
            icon="building"
            iconStyle="far"
            text={links.organisations.text}
            href={links.organisations.url}
          />
        )}
        <MenuLink icon="wrench" text="Admin" href={links.admin} />
        <MenuLink icon="user" text="User Profile" href={links.userProfile} />
        <MenuLink
          icon="lock"
          text="Change Password"
          href={links.changePassword}
        />
        <MenuLink
          icon="eye-slash"
          text="Private Mode"
          href={links.privateMode}
        />
        <MenuLink icon="power-off" text="Log Out" href={links.logout} />
      </ul>
    );
  }

  return (
    <>
      <button className={styles.accountButton} onClick={handleButtonClick}>
        Account
        <span className={styles.downArrow} />
      </button>

      {menu}
    </>
  );
}
